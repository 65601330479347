import React from 'react';
import Products from '../components/categoryPages/Products';
import Seo from '../components/Seo';
import useProducts from '../hooks/useProducts';
import { getProductsFromTag } from '../utils/helperFunctions';
import { categoryHolder } from './category.module.scss';

export default function TagPage({ pageContext: tag }) {
  const products = useProducts();
  const productsInTag = getProductsFromTag(tag.slug, products, true);

  return (
    <article className={categoryHolder}>
      <h1>{tag.name}</h1>
      <Products products={productsInTag} />
    </article>
  );
}

export const Head = ({ pageContext: tag }) => <Seo title={`${tag.name} equipment for hire in London`} />;
